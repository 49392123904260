import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  booleanAttribute,
  inject,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import classnames from 'classnames';
import { IconCheckComponent } from '@pedix-workspace/angular-ui-icons';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';

let INPUT_UID = 0;

export type InputCheckboxLabelSize = 'xs' | 'sm' | 'md';

export type InputCheckboxValueType = boolean | undefined | null;

@Component({
  selector: 'pxw-input-checkbox',
  standalone: true,
  templateUrl: './input-checkbox.component.html',
  styleUrl: './input-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputCheckboxComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputCheckboxComponent,
    },
  ],
  imports: [NgClass, IconCheckComponent, NgTemplateOutlet],
})
export class InputCheckboxComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any> | undefined;

  @Input({ required: true }) name: string;
  @Input() label: string;
  @Input() labelSize: InputCheckboxLabelSize = 'sm';
  @Input() helper: string;
  @Input() legend: string;
  @Input() autofocus: boolean;
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;

  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input({ transform: booleanAttribute }) requiredTrue?: boolean;

  private injector = inject(Injector);

  protected value: InputCheckboxValueType;
  protected inputId: string;
  protected isDisabled: boolean;

  private onChange: (value: InputCheckboxValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  @HostBinding('class') get classNames() {
    return classnames('ui-input', `ui-input-checkbox`, {
      'ui-input--with-helper': this.helper,
      'ui-input--with-errors': !this.isValid,
    });
  }

  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }

  get isRequired() {
    return (
      this.formControl?.hasValidator(Validators.required) ||
      this.required === true ||
      this.formControl?.hasValidator(Validators.requiredTrue) ||
      this.requiredTrue === true
    );
  }

  get isChecked() {
    return this.value === true ? true : null;
  }

  get isValid() {
    return this.formControl?.valid;
  }

  get isTouched() {
    return this.formControl?.touched;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }

  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }

  get option() {
    return {
      key: this.value,
      label: this.legend,
    };
  }

  ngOnInit(): void {
    this.inputId = `input-checkbox-${++INPUT_UID}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: InputCheckboxValueType) {
    this.value = value || null;
  }
  registerOnChange(onChange: (value: InputCheckboxValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onToggle($event: Event) {
    if (this.isDisabled) {
      return;
    }
    const nextValue = this.value ? false : true;

    this.writeValue(nextValue);
    this.onChange(nextValue);
    this.onValidatorChange();
    this.onTouched();
  }

  focus() {
    this.input.nativeElement.focus();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getErrorMessage([key, data]: [string, any]) {
    return this.messages?.[key] || this.formReactiveConfigService.getErrorMessage(key, data);
  }
}
