import { SimpleChanges } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

export interface WithDefaultValidatorProps {
  required?: boolean;
  requiredTrue?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string | RegExp;
  email?: boolean;
  min?: number;
  max?: number;
  custom?: ValidatorFn;
}

export const hasChangedAnyDefaultValidatorProps = (changes: SimpleChanges): boolean => {
  if (
    changes['required']?.currentValue !== changes['required']?.previousValue ||
    changes['requiredTrue']?.currentValue !== changes['requiredTrue']?.previousValue ||
    changes['email']?.currentValue !== changes['email']?.previousValue ||
    changes['minLength']?.currentValue !== changes['minLength']?.previousValue ||
    changes['maxLength']?.currentValue !== changes['maxLength']?.previousValue ||
    changes['pattern']?.currentValue !== changes['pattern']?.previousValue ||
    changes['min']?.currentValue !== changes['min']?.previousValue ||
    changes['max']?.currentValue !== changes['max']?.previousValue ||
    changes['custom']?.currentValue !== changes['custom']?.previousValue
  ) {
    return true;
  }
  return false;
};

export const composeValidatorFn = (component: WithDefaultValidatorProps): ValidatorFn | null => {
  const validators = [];

  if (component.required) {
    validators.push(Validators.required);
  }
  if (component.requiredTrue) {
    validators.push(Validators.requiredTrue);
  }
  if (component.minLength) {
    validators.push(Validators.minLength(component.minLength));
  }
  if (component.maxLength) {
    validators.push(Validators.maxLength(component.maxLength));
  }
  if (component.pattern) {
    validators.push(Validators.pattern(component.pattern));
  }
  if (component.email) {
    validators.push(Validators.email);
  }
  if (component.min !== undefined) {
    validators.push(Validators.min(component.min));
  }
  if (component.max !== undefined) {
    validators.push(Validators.max(component.max));
  }
  if (component.custom) {
    validators.push(component.custom);
  }
  if (validators.length > 0) {
    return Validators.compose(validators);
  }
  return null;
};
