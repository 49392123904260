import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';
import { IconComponent } from '@pedix-workspace/angular-ui-icons';
import classnames from 'classnames';

export const tagSizes = ['xs', 'sm', 'md', 'lg'] as const;
export const tagTypes = ['neutral', 'info', 'warning', 'danger', 'success'] as const;
export const tagAppareances = ['solid', 'bare', 'accented'] as const;

export type TagSizes = typeof tagSizes;
export type TagSize = TagSizes[number];

export type TagTypes = typeof tagTypes;
export type TagType = TagTypes[number];

export type TagAppareances = typeof tagAppareances;
export type TagAppareance = TagAppareances[number];

@Component({
  selector: 'pxw-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class TagComponent implements AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;

  @Input() size: TagSize = 'sm';
  @Input() type: TagType = 'info';
  @Input() appareance: TagAppareance = 'solid';

  @HostBinding('class')
  get classes() {
    return classnames(`size-${this.size}`, `type-${this.type}`, `appareance-${this.appareance}`);
  }

  ngAfterContentInit(): void {
    if (this.icon) {
      this.icon.size = this.size;
    }
  }
}
