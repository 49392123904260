@if (href) {
  <a class="wrapper" [href]="href" [target]="target">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
} @else {
  <button class="wrapper" [type]="mode" [attr.form]="formId">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
}
<ng-template #button>
  <button class="wrapper" [type]="mode">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-template>

<ng-template #buttonContent>
  @if (loading) {
    <pxw-icon icon="loading" [size]="iconSize" [spin]="true"></pxw-icon>
  }
  @if (!loading && icon) {
    <ng-content select="pxw-icon:not(.icon-right)"></ng-content>
  }
  <span #contentWrapper class="text">
    <ng-content></ng-content>
  </span>
  <ng-content select="pxw-icon.icon-right"></ng-content>
</ng-template>
