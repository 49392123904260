import { isPlatformBrowser, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  OnInit,
  ContentChild,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IconCheckComponent, IconCloseComponent } from '@pedix-workspace/angular-ui-icons';
import slugify from 'slugify';
import classnames from 'classnames';
import { ModalHistoryComponent } from '../modal-history/modal-history.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import {
  CardFooterComponent,
  CardHeaderComponent,
  CardComponent,
} from '@pedix-workspace/angular-ui-blocks';

export const modalFormStyles = ['small', 'normal', 'wide', 'embeeded', 'fullscreen'] as const;

export type ModalFormStyles = typeof modalFormStyles;
export type ModalFormStyle = ModalFormStyles[number];

export type CloseReason = 'close' | 'dismiss' | 'backbutton';

@Component({
  selector: 'pxw-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    CardHeaderComponent,
    NgTemplateOutlet,
    IconCloseComponent,
    IconCheckComponent,
    CardFooterComponent,
    ButtonComponent,
    ModalHistoryComponent,
  ],
})
export class ModalFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modalContentWrapper', { read: TemplateRef }) modalContentWrapper!: TemplateRef<any>;

  @ContentChild('modalTitle') modalTitle: TemplateRef<HTMLElement>;
  @ContentChild('modalContent') modalContent: TemplateRef<HTMLElement>;
  @ContentChild('modalFooter') modalFooter: TemplateRef<any> | null = null;

  @Output() modalAction = new EventEmitter<NgbModalRef>();
  @Output() modalClose = new EventEmitter<CloseReason>();
  @Output() modalOpen = new EventEmitter<boolean>();

  @Input() openOnInit = false;
  @Input() loading = false;
  @Input() title = '';
  @Input() buttonText = '';
  @Input() buttonDisabled: boolean;
  @Input() modalStyle: ModalFormStyle = 'normal';
  @Input() isBoundToRoute = false;
  @Input() hideCloseButton = false;
  @Input() disablePadding = false;
  @Input() modalClasses: string;
  @Input() contentScrollEnabled = true;

  @HostBinding('class') get classNames() {
    return classnames(this.modalStyle, {
      'with-footer': this.buttonText,
    });
  }

  modalRef!: NgbModalRef;
  isOpen = false;
  bindModalToHistory = false;
  closeMethod: CloseReason | undefined;
  modalFormName = 'modal-form';

  private platformId = inject(PLATFORM_ID);

  private modalService = inject(NgbModal);

  constructor() {}

  ngOnInit() {
    this.modalFormName = slugify(this.title);
  }

  ngAfterViewInit() {
    if (this.openOnInit && isPlatformBrowser(this.platformId)) {
      this.open(true);
    }
  }

  ngOnDestroy() {
    if (!this.isOpen) {
      return;
    }
    this.toggleOpen(false);

    if (this.closeMethod === 'close') {
      this.modalRef?.close();
    } else {
      // If the browser's back button is pressed, then we consider this action to be a "dismiss"
      // As this wasn't triggered by the UI, we need to emit the event in case some other component is interested on it
      if (!this.closeMethod) {
        this.modalClose.emit('dismiss');
      }
      this.modalRef?.dismiss();
    }
  }

  close(reason: CloseReason = 'close') {
    if (!this.isOpen) {
      return;
    }

    // NOTE: for some reason, this should be called asynchronously to prevent the modal opening again
    setTimeout(() => {
      this.closeMethod = reason;
      this.modalClose.emit(this.closeMethod);
    }, 50);

    if (!this.isBoundToRoute) {
      this.modalRef.dismiss();
      this.toggleOpen(false);
    }

    setTimeout(() => {
      this.closeMethod = undefined;
    }, 100);
  }

  action() {
    this.modalAction.emit(this.modalRef);
  }

  open(bindModalToHistory = false) {
    this.toggleOpen(true);
    this.modalRef = this.modalService.open(this.modalContentWrapper, {
      windowClass: `animated bounceInUp pxw-modal-form ${this.modalStyle} ${this.modalClasses}`,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    if (bindModalToHistory) {
      this.bindModalToHistory = bindModalToHistory;
    }
  }

  private toggleOpen(isOpen: boolean) {
    this.isOpen = isOpen;

    this.modalOpen.emit(this.isOpen);
  }
}
