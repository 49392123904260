import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { TranslocoDirective } from '@ngneat/transloco';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { CurrencyFormatPipe, PriceDiscountPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import classnames from 'classnames';

export type ProductPriceLayout =
  | 'product-item'
  | 'presentation-item'
  | 'product-detail'
  | 'option-item'
  | 'resume-item';

@Component({
  selector: 'pxw-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, PriceDiscountPipe, CurrencyFormatPipe, TagComponent],
})
export class ProductPriceComponent {
  @Input() price: number;
  @Input() priceDiscount?: number;
  @Input() layout: ProductPriceLayout = 'option-item';
  @Input() maxDiscount: number;

  @HostBinding('class')
  get classNames() {
    return classnames(`layout-${this.layout}`, {
      'with-discount': this.priceDiscount,
    });
  }

  get displayPercentage() {
    return ['product-item', 'presentation-item', 'option-item'].includes(this.layout);
  }
}
