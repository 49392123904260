import {
  Component,
  Input,
  HostBinding,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  inject,
  ContentChild,
  AfterContentInit,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { IconComponent, IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import classnames from 'classnames';

import { NgTemplateOutlet } from '@angular/common';

export const buttonColors = [
  'blue',
  'pink',
  'yellow',
  'cyan',
  'theme1',
  'theme1-contrast',
  'theme2',
  'theme2-contrast',
  'silver',
  'whatsapp',
  'link',
] as const;
export const buttonAppareances = ['solid', 'outline', 'link'] as const;
export const buttonDisplays = ['block', 'inline'] as const;
export const buttonSizes = ['sm', 'md', 'lg'] as const;
export const buttonModes = ['button', 'submit'] as const;
export const buttonIconPositions = ['left', 'right'] as const;

export type ButtonColors = typeof buttonColors;
export type ButtonColor = ButtonColors[number];

export type ButtonAppareances = typeof buttonAppareances;
export type ButtonAppareance = ButtonAppareances[number];

export type ButtonDisplays = typeof buttonDisplays;
export type ButtonDisplay = ButtonDisplays[number];

export type ButtonSizes = typeof buttonSizes;
export type ButtonSize = ButtonSizes[number];

export type ButtonModes = typeof buttonModes;
export type ButtonMode = ButtonModes[number];

export type ButtonIconPositions = typeof buttonIconPositions;
export type ButtonIconPosition = ButtonIconPositions[number];

@Component({
  selector: 'pxw-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, IconLoadingComponent],
})
export class ButtonComponent implements AfterViewInit, AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;
  @ViewChild('contentWrapper') contentWrapper: ElementRef<HTMLElement>;

  @Input() appareance: ButtonAppareance = 'solid';
  @Input() color: ButtonColor = 'cyan';
  @Input() mode: ButtonMode = 'button';
  @Input() display: ButtonDisplay = 'inline';
  @Input() size: ButtonSize = 'sm';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() rounded = false;
  @Input() iconPosition: ButtonIconPosition = 'left';
  @Input() href: string | SafeUrl;
  @Input() target: '_self' | '_blank' = '_self';
  @Input() formId: string;

  @HostBinding('class') get classes() {
    return classnames(
      `appareance-${this.appareance}`,
      `color-${this.color}`,
      `display-${this.display}`,
      `size-${this.size}`,
      {
        loading: this.loading,
        rounded: this.rounded,
        'with-icon': this.icon,
        [`icon-position-${this.iconPosition}`]: this.icon,
      },
    );
  }

  @HostBinding('attr.disabled') get isDisabled() {
    return this.loading || this.disabled ? true : null;
  }

  private rootEl = inject(ElementRef);

  get iconSize() {
    switch (this.size) {
      case 'sm':
        return 'xs';
      case 'md':
        return 'sm';
      case 'lg':
        return 'sm';
    }
  }

  ngAfterViewInit(): void {
    const hasContent = this.checkHasContent();

    if (hasContent === false) {
      this.rootEl.nativeElement.classList.add('icon-button');
    }
    if (this.icon) {
      this.icon.size = this.iconSize;
    }
  }

  ngAfterContentInit(): void {
    if (this.icon) {
      this.icon.size = this.iconSize;
    }
  }

  checkHasContent() {
    return Array.from(this.contentWrapper.nativeElement.childNodes).some(
      node => node.nodeType !== 8,
    );
  }
}
