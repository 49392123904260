import {
  CustomFieldDetail,
  EndOrderDetails,
  Address,
  CartItem,
  PaymentMethod,
  FirestoreTimestamp,
  firestoreDateParser,
  ProductOptionRangeType,
} from '@pedix-workspace/utils';
import {
  OrderHeadingParams,
  OrderItemsByCategories,
  OrderTotalParams,
  OrderItem,
  OrderItemType,
} from '../order-message.types';
import { format } from 'date-fns';
import { parseCustomFieldValue } from '../parse-custom-field-values';

export function endOrderToOrderHeadingParams(
  endOrder: EndOrderDetails,
  siteUrl: string,
): OrderHeadingParams {
  const displayMapUrl = endOrder.personalInformation.address?.addressDetails?.url;

  return {
    id: endOrder.id,
    sequenceId: endOrder.sequenceId,
    date: firestoreDateParser(endOrder.created),
    name: endOrder.personalInformation.name,
    phone: endOrder.personalInformation.phone,
    paymentMethod: endOrder.paymentMethod?.name,
    paymentMethodType: endOrder.paymentMethod?.type,
    couponCode: endOrder.couponConfigurationSnapshot?.code,
    finalAmount: endOrder.finalAmount,
    paymentMethodHowMuch: endOrder.howMuch,
    paymentLink: hasPaymentLink(endOrder.paymentMethod)
      ? getPaymentUrl(siteUrl, endOrder.id)
      : undefined,
    paymentLegend: endOrder.paymentMethod?.legend?.trim(),
    shippingOptionType: endOrder.shippingOption.type,
    address: getAddress(endOrder.personalInformation.address),
    addressReferences: endOrder.personalInformation.address.references,
    mapUrl: displayMapUrl ? getMapUrl(siteUrl, endOrder.id) : undefined,
    tableNumber: endOrder.tableNumber,
    customFieldDetails: getSanitizedCustomFieldDetails(endOrder.customFieldDetails),
  };
}

export function endOrderToOrderItemsByCategories(cartItems: CartItem[]): OrderItemsByCategories {
  const cartItemsByCategory = getCartItemsByCategory(cartItems);
  const categoryNames = Object.keys(cartItemsByCategory);

  return {
    categories: categoryNames.map(categoryName => {
      const itemsByGroup = cartItemsByCategory[categoryName];
      const groupNames = Object.keys(itemsByGroup);

      // Leave undefined groups at the end
      groupNames.sort(sortAsscending);

      const groups = groupNames.map(groupName => ({
        name: groupName === 'undefined' ? undefined : groupName,
        isCustomGroup: groupName !== 'undefined',
        items: endOrderCartItemsToOrderItems(itemsByGroup[groupName]),
      }));
      return {
        categoryName,
        hasCustomGroups: groupNames.some(groupName => groupName !== 'undefined'),
        groups,
      };
    }),
  };
}

export function endOrderCartItemsToOrderItems(cartItems: CartItem[]): OrderItem[] {
  return cartItems.map<OrderItem>(cartItem => {
    const itemType = getItemType(cartItem.selectedPresentation);
    let productOrPresentationSku;

    if (itemType === 'single-presentation') {
      productOrPresentationSku = cartItem.selectedPresentation.items[0].sku;
    } else if (itemType === 'regular') {
      productOrPresentationSku = cartItem.product.sku;
    }

    return {
      cartItem: cartItem,
      productName: cartItem.product.name,
      productOrPresentationSku,
      total: getProductTotal(cartItem, itemType),
      quantity: getProductQuantity(cartItem, itemType),
      unitPrice: getProductUnitPrice(cartItem, itemType),
      observations: cartItem.observations,
      type: itemType,
      options: cartItem.selectedOptions?.map(({ option, items }) => ({
        name: option.name,
        items: items.map(item => ({
          name: item.name,
          quantity: (item.quantity || 1) * cartItem.qty,
          price: item.price * (item.quantity || 1) * cartItem.qty,
          unitPrice: item.price,
        })),
      })),
      presentationName:
        itemType === 'single-presentation'
          ? cartItem.selectedPresentation.items[0].name
          : undefined,
      presentations:
        itemType === 'multi-presentation'
          ? {
              items: cartItem.selectedPresentation.items.map(item => ({
                name: item.name,
                sku: item.sku,
                quantity: item.quantity || 1,
                price: item.price * (item.quantity || 1),
                unitPrice: item.price,
              })),
            }
          : undefined,
    };
  }, []);
}

export function sortAsscending(a: string, b: string): number {
  if (a === b) {
    return 0;
  }
  if (a === 'undefined') {
    return 1;
  }
  if (b === 'undefined') {
    return -1;
  }
  return a < b ? -1 : 1;
}

export function getEndOrderToOrderTotalParams(endOrder: EndOrderDetails): OrderTotalParams {
  const deliveryCost = endOrder.deliveryCost;
  const outOfDeliveryZone = endOrder.outOfDeliveryZone;

  return {
    totalAmount: endOrder.totalAmount,
    totalProductDiscount: endOrder.totalProductDiscount,
    finalAmount: endOrder.finalAmount,
    couponDiscount: endOrder.couponDiscount,
    deliveryCost,
    outOfDeliveryZone,
    extraChargeAmount: endOrder.paymentMethodExtraCharge,
    discountAmount: endOrder.paymentMethodDiscount,
    foodBankDonationAmount: endOrder.foodBankDonationAmount,
  };
}

export function getSanitizedCustomFieldDetails(customFieldDetails?: CustomFieldDetail[]) {
  if (!customFieldDetails || customFieldDetails?.length === 0) {
    return undefined;
  }
  const sanitizedCustomFields = customFieldDetails
    .map(customFieldDetail => ({
      name: customFieldDetail.field.name,
      value: getCustomFieldDetailsValue(customFieldDetail),
    }))
    .filter(customField => Boolean(customField.value));

  return sanitizedCustomFields.length > 0 ? sanitizedCustomFields : undefined;
}

export function getCustomFieldDetailsValue(customFieldDetail: CustomFieldDetail): string {
  const parsedValue = parseCustomFieldValue(
    customFieldDetail.value,
    customFieldDetail.field.fieldType,
  );

  if (!parsedValue) {
    return null;
  }

  if (Array.isArray(parsedValue)) {
    return parsedValue.join(', ');
  } else if (customFieldDetail.field.fieldType === 'date') {
    if (parsedValue instanceof Date) {
      return format(parsedValue, 'dd/MM/yy');
    }
    if ((parsedValue as FirestoreTimestamp)?.toDate) {
      return format((parsedValue as FirestoreTimestamp)?.toDate(), 'dd/MM/yy');
    }
    return parsedValue.toString();
  } else {
    return parsedValue.toString();
  }
}

export function hasPaymentLink(paymentMethod: PaymentMethod) {
  if (paymentMethod?.hasLink) {
    return true;
  }
  return false;
}

export function getMapUrl(siteUrl: string, orderId: string) {
  return `${siteUrl}/pedidos/${orderId}/mapa`;
}

export function getPaymentUrl(siteUrl: string, orderId: string) {
  return `${siteUrl}/pedidos/${orderId}/pago`;
}

export function getAddress(address: Address, options: { includeReferences?: boolean } = {}) {
  let text = address.addressDetails ? address.addressDetails.fullAddress : address.street;

  if (address.isAFlat) {
    text += `, ${address.floorAndNumber}`;
  }

  if (options.includeReferences && address.references) {
    text += ` (${address.references})`;
  }

  return text;
}

export function getCartItemsByCategory(
  cartItems: CartItem[],
): Record<string, Record<string, CartItem[]>> {
  // CategoryName -> GroupName -> Products[]
  const itemsByCategory: Record<string, Record<string, CartItem[]>> = {};

  // Groups items before processing
  cartItems.map(cartItem => {
    const categoryName = cartItem.category.name;
    const groupName = cartItem.product.groupName || 'undefined';

    if (!itemsByCategory[categoryName]) {
      itemsByCategory[categoryName] = {};
    }
    if (!itemsByCategory[categoryName][groupName]) {
      itemsByCategory[categoryName][groupName] = [];
    }
    itemsByCategory[categoryName][groupName].push(cartItem);
  });

  return itemsByCategory;
}

export function getItemType(selectedPresentation: CartItem['selectedPresentation']): OrderItemType {
  if (!selectedPresentation || selectedPresentation?.items.length === 0) {
    return 'regular';
  }
  if (selectedPresentation.items.length > 1) {
    return 'multi-presentation';
  }
  return 'single-presentation';
}

export function getProductQuantity(cartItem: CartItem, itemType: OrderItemType) {
  if (itemType === 'multi-presentation') {
    return getPresentationsCount(cartItem.selectedPresentation);
  }
  if (itemType === 'single-presentation') {
    return cartItem.selectedPresentation.option.rangeType === ProductOptionRangeType.FIXED
      ? cartItem.qty || 1
      : cartItem.selectedPresentation.items[0].quantity || 1;
  }
  return cartItem.qty;
}

export function getProductUnitPrice(cartItem: CartItem, itemType: OrderItemType) {
  if (itemType === 'regular') {
    return cartItem.product.price;
  }
  if (itemType === 'single-presentation') {
    return cartItem.selectedPresentation.items[0].price;
  }
  return 0;
}

export function getProductTotal(cartItem: CartItem, itemType: OrderItemType) {
  if (itemType === 'regular') {
    return cartItem.product.price * (cartItem.qty || 1);
  }
  if (itemType === 'single-presentation') {
    const quantity =
      cartItem.selectedPresentation.option.rangeType === ProductOptionRangeType.FIXED
        ? cartItem.qty || 1
        : cartItem.selectedPresentation.items[0].quantity || 1;

    return cartItem.selectedPresentation.items[0].price * quantity;
  }
  return 0;
}

export function getPresentationsCount(selectedPresentation: CartItem['selectedPresentation']) {
  return selectedPresentation.items?.reduce(
    (sum, presentationItem) => sum + presentationItem.quantity,
    0,
  );
}

export const applyFormatToEveryLine = (
  message: string,
  formatLine: (line: string, index?: number) => string,
) => {
  const lines = message.split(/\n|\r/);

  return lines.map((line, index) => (line ? formatLine(line, index) : line)).join('\n');
};

export const hasAnyPriceModifier = (
  priceModifiers: Pick<
    OrderTotalParams,
    | 'totalProductDiscount'
    | 'deliveryCost'
    | 'couponDiscount'
    | 'extraChargeAmount'
    | 'discountAmount'
    | 'foodBankDonationAmount'
  >,
): boolean => {
  return [
    priceModifiers.totalProductDiscount,
    priceModifiers.deliveryCost,
    priceModifiers.couponDiscount,
    priceModifiers.discountAmount,
    priceModifiers.extraChargeAmount,
    // Food Bank Donation
    priceModifiers.foodBankDonationAmount,
  ].some(priceModifier => priceModifier > 0);
};
