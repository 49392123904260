import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { getCartItemSubtotal } from '@pedix-workspace/orders';
import {
  TypesOfShippings,
  CartItem,
  EndOrderDetails,
  ShippingOption,
  PersonalInformation,
  EndOrderDetailsSessionData,
  CustomField,
} from '@pedix-workspace/utils';
import subHours from 'date-fns/subHours';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { ApiV1_Establishment } from '@pedix-workspace/api-v1';

@Injectable({
  providedIn: 'root',
})
export class EndOrderService {
  private readonly PERSONAL_INFORMATION_KEY = 'PERSONAL_INFORMATION';
  private readonly SESSION_STORAGE_KEY = 'PXW_ORDER_SESSION';

  private t = inject(TranslocoService);
  private cookieService = inject(CookieService);

  get personalInformationData(): PersonalInformation {
    const personalInformationValue: string =
      this.cookieService.get(this.PERSONAL_INFORMATION_KEY) ||
      localStorage.getItem(this.PERSONAL_INFORMATION_KEY);

    if (personalInformationValue) {
      return JSON.parse(personalInformationValue);
    }
    return this.getEmptyPersonalInformation();
  }

  set storePersonalInformationData(personalInformation) {
    let domain: string;

    if (location.href.includes('pedix.local')) {
      domain = '.pedix.local';
    } else if (environment.production) {
      domain = '.pedix.app';
    } else {
      domain = '.pedix.dev';
    }

    const cookieOptions: CookieOptions = {
      domain,
      sameSite: 'Strict',
      path: '/',
      secure: domain !== '.pedix.local',
    };

    const personalInformationString = JSON.stringify(personalInformation);

    this.cookieService.set(this.PERSONAL_INFORMATION_KEY, personalInformationString, cookieOptions);
    localStorage.setItem(this.PERSONAL_INFORMATION_KEY, personalInformationString);
  }

  set endOrderSessionData(details: EndOrderDetailsSessionData['details']) {
    const endOrderDetailsSessionData: EndOrderDetailsSessionData = {
      updatedAt: new Date(),
      details,
    };
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(endOrderDetailsSessionData));
  }

  get endOrderSessionData(): EndOrderDetailsSessionData['details'] {
    const sessionData = sessionStorage.getItem(this.SESSION_STORAGE_KEY);

    if (sessionData) {
      const endOrderSessionData: EndOrderDetailsSessionData = JSON.parse(
        sessionStorage.getItem(this.SESSION_STORAGE_KEY),
      );
      const updatedAt = new Date(endOrderSessionData.updatedAt);

      if (updatedAt < subHours(Date.now(), 1)) {
        this.clearSessionData();
      } else {
        // NOTE: refreshes session timer
        this.endOrderSessionData = endOrderSessionData.details;

        return endOrderSessionData.details;
      }
    }
    return {};
  }

  clearSessionData() {
    sessionStorage.removeItem(this.SESSION_STORAGE_KEY);
  }

  createEndOrderDetails(options?: {
    cartItems: CartItem[];
    establishment: ApiV1_Establishment;
    customFields?: CustomField[];
  }): EndOrderDetails {
    const cartItems = options.cartItems;
    const establishment = options.establishment;
    const customFields = options.customFields || [];
    const totalAmount = this.calculateCartItemsTotal(cartItems, { usePriceDiscount: false });
    const totalAmountWithDiscount = this.calculateCartItemsTotal(cartItems, {
      usePriceDiscount: true,
    });
    const totalProductDiscount = totalAmount - totalAmountWithDiscount;
    const finalAmount = totalAmountWithDiscount;

    return {
      created: new Date(),
      updated: new Date(),
      status: 'new',
      sequenceId: 0,
      establishmentId: establishment.id,
      personalInformation: this.personalInformationData,
      deliveryCost: 0,
      paymentMethodExtraCharge: 0,
      paymentMethodDiscount: 0,
      cartItems,
      totalAmount,
      totalAmountWithDiscount,
      totalProductDiscount,
      finalAmount,
      customFieldDetails: customFields.map(customField => ({
        field: customField,
        value: customField.defaultValue || '',
      })),
      tags: [],
      schemaVersion: 7,
      ...this.endOrderSessionData,
    };
  }

  calculateCartItemsTotal(cartItems: CartItem[], options: { usePriceDiscount: boolean }) {
    return cartItems.reduce(
      (total, cartItem) =>
        (total += getCartItemSubtotal(cartItem, { usePriceDiscount: options.usePriceDiscount })),
      0,
    );
  }

  calculatePriceModifierAmount(endOrderDetails: EndOrderDetails) {
    if (!endOrderDetails.paymentMethod || endOrderDetails.paymentMethod.priceModifier === 'none') {
      return 0;
    }
    const subtotal =
      endOrderDetails.totalAmount -
      endOrderDetails.totalProductDiscount -
      (endOrderDetails.couponDiscount || 0) +
      (endOrderDetails.deliveryCost || 0);

    if (
      endOrderDetails.paymentMethod.priceModifier === 'extraCharge' &&
      endOrderDetails.paymentMethod.extraCharge
    ) {
      return (subtotal * endOrderDetails.paymentMethod.extraCharge) / 100;
    } else if (
      endOrderDetails.paymentMethod.priceModifier === 'discount' &&
      endOrderDetails.paymentMethod.discount
    ) {
      return (subtotal * endOrderDetails.paymentMethod.discount) / -100;
    } else {
      return 0;
    }
  }

  calculateTotalToBePaid(endOrderDetails: EndOrderDetails) {
    const subtotal =
      endOrderDetails.totalAmount -
      endOrderDetails.totalProductDiscount -
      (endOrderDetails.couponDiscount || 0) +
      (endOrderDetails.deliveryCost || 0);

    // NOTE: this is possitive for extraCharge, and negative for discount
    const priceModifierAmount = this.calculatePriceModifierAmount(endOrderDetails);

    return subtotal + priceModifierAmount + (endOrderDetails.foodBankDonationAmount || 0);
  }

  getShippingOptions(shippingOptionsAvailables) {
    const shippingOptions: ShippingOption[] = [];

    shippingOptionsAvailables.forEach((shippingOptionsAvailable: TypesOfShippings) => {
      switch (shippingOptionsAvailable) {
        case TypesOfShippings.IN_PLACE:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionInPlace'),
            type: TypesOfShippings.IN_PLACE,
          });
          break;
        case TypesOfShippings.TAKE_AWAY:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionTakeAway'),
            type: TypesOfShippings.TAKE_AWAY,
          });
          break;
        case TypesOfShippings.DELIVERY:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionDelivery'),
            type: TypesOfShippings.DELIVERY,
          });
          break;
        default:
          break;
      }
    });

    return shippingOptions;
  }

  getEmptyPersonalInformation(): PersonalInformation {
    return {
      name: '',
      phone: '',
      address: undefined,
    };
  }
}
