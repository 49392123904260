@for (category of orderItems.categories; track category) {
  <div class="category-wrapper">
    <h2 class="category-name">{{ category.categoryName }}</h2>
    @for (group of category.groups; track group) {
      <div class="group-wrapper">
        @if (category.hasCustomGroups) {
          <h3 class="group-name">
            {{ group.name || undefinedGroupName }}
          </h3>
        }
        <div class="items-wrapper">
          @for (item of group.items; let index = $index; track item.cartItem.id) {
            <ng-container
              *ngTemplateOutlet="itemTemplate; context: { $implicit: item.cartItem, index }"
            />
          }
        </div>
      </div>
    }
  </div>
}
